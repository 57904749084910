export default {
    home: '/',
    calcul: '/calcul',
    regions: '/regions',
    form: '/form',
    etapes: '/etapes-de-vente',
    vente: 'https://www.allowa.com/?category=vente',
    location: 'https://www.allowa.com/?category=location',
    vendre: 'https://vendre.allowa.com/',
    conseil: 'https://conseil.allowa.com/',
    mandataires: 'https://network.allowa.com/',
    estimateur: 'http://vendre.allowa.com/estimation',
    dossier_location: '/',
    dossier_acquereur: '/',
    espace_vendeur: '/',
    mission: 'https://www.allowa.com/notre-mission',
    equipe: 'https://www.allowa.com/notre-equipe',
    faq: 'https://allowa.crisp.help/fr/',
    website: 'https://www.allowa.com/',
    facebook: 'https://www.facebook.com/allowaFR/',
    instagram: 'https://www.instagram.com/allowa_immobilier/',
    linkedin: 'https://www.linkedin.com/company/allowa-fr',
    legal_notice: 'https://www.allowa.com/mentions-legales',
    privacy_policy: 'https://www.allowa.com/politique-de-confidentialite',
    cgu: 'https://www.allowa.com/cgu',
    cookies: 'https://www.allowa.com/politique-de-cookies',
    fees: 'https://www.allowa.com/baremes',
    data_policy_sales: 'https://www.allowa.com/politique-des-donnees-personnelles-vente',
    data_policy_lettings: 'https://www.allowa.com/politique-des-donnees-personnelles-location'
}